import React from "react"
import styled from 'styled-components'
import QuoteBG from "../images/testimonials_bg.png"
import { Wrapper, TPLarge, TPReg, Button, CSecondary, CDark, above, TH2, CLight } from "./global"

const Blockquote = styled.section`
    background-image: url(${QuoteBG});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 0;
    color: ${CLight};
    text-align: center;
    ${above.small`
        padding: 120px 0;
    `}
    div {
        max-width: 720px;
    }
    p:nth-child(2) {
        margin: 30px 0 60px;
    }
`

const Quote = () => (
    <Blockquote>
        <Wrapper>
            <TPLarge>“I have always received a professional and efficient service with Hoylake Autos. There is no one else I would have do my cars as they work so hard and are dedicated to what they do.”</TPLarge>
            <TPReg>&mdash; Sophie Lewis</TPReg>
            <Button to="/testimonials">Read More Testimonials</Button>
        </Wrapper>
    </Blockquote>
)
  
export default Quote
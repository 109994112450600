import React from "react"
import styled from 'styled-components'
import { Wrapper, CDark, CPrimary, CLight } from "./global"

const AlertBar = styled.section`
    background: ${CDark};
    color: ${CLight};
    text-align: center;
    font-size: 0.875rem;
    padding: 32px 0;
    border-top: 1px solid rgba(255, 255, 255, .2);
    span {
        color: ${CPrimary};
        font-weight: 700;
    }
    img {
        display: inline;
        margin: 0 16px 0 0;
    }
`

const Alert = ({ alertIcon, alertTitle, alertText }) => (
    <AlertBar>
        <Wrapper>
            <p><img src={alertIcon} width="24" height="24" alt="Alert Icon" /><span>{alertTitle}: </span>{alertText}</p>
        </Wrapper>
    </AlertBar>
  )
  
export default Alert
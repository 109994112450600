import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Alert from "../components/alert"
import HeaderImg from "../images/header_bg_2.png"
import IconWarn from "../images/icon_alert.svg"
import { above, TH2, TPReg, Wrapper } from "../components/global"
import Quote from "../components/quote"
import PicGareth from "../images/mechanic1.jpg"
import PicRoss from "../images/mechanic2.jpg"

const Team = styled.section`
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 40px 0;
    ${above.small`
        grid-template-columns: 1fr 1fr;
        gap: 150px;
        padding: 120px 0;
    `}
    h2 {
        margin: 30px 0 20px;
    }
`

const Member = styled.div`

`

const TeamPage = () => (
  <Layout pageTitle="Meet The Team" pageText="Our team consists of highly qualified and motivated professionals, who are all experts in their field. With many years of experience in the industry, they have the expertise to provide comprehensive, first-rate services to our clients." pageBG={HeaderImg}>
    <SEO title="Meet The Team" />
    <Alert alertIcon={IconWarn} alertTitle="Covid-19" alertText="We can do contactless payments &amp; email invoices. We disinfect all keys and steering wheels etc before &amp; after we get in your vehicle!" />
    
    <Wrapper>
        <Team>
            <Member>
                <img src={PicGareth} alt="Gareth Barrett" />
                <TH2>Gareth Barrett</TH2>
                <TPReg>Gareth started his career in 2005 as an apprentice in the Vag group, There he gained an Nvq level 3 in vehicle maintenance and repair and an Ata licence.  In 2011 he started working for a brand independant company were he honed his skill and became manager of the workshop.</TPReg>
            </Member>
            <Member>
                <img src={PicRoss} alt="Ross Parry" />
                <TH2>Ross Parry</TH2>
                <TPReg>Ross started his career in 2010 as an apprentice in the Vag group. There he gained an Nvq level 3 in vehicle maintenance and repair and acquired his MOT licence. He honed his skill on diagnostics and vehicle repair, gaining valuable experience on all types of vehicles.</TPReg>
            </Member>
        </Team>
    </Wrapper>

    <Quote />
  </Layout>
)

export default TeamPage
